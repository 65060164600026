import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, NavigationArea } from './styles';

const HeaderBreadcrumbs = ({ breadcrumbsItems }) => (
  <NavigationArea>
    <Breadcrumbs responsiveBreakpoint="0" items={breadcrumbsItems} />
  </NavigationArea>
);

HeaderBreadcrumbs.propTypes = {
  breadcrumbsItems: PropTypes.arrayOf(PropTypes.any),
};

HeaderBreadcrumbs.defaultProps = {
  breadcrumbsItems: [],
};

export default HeaderBreadcrumbs;
